html {
  height: 100%;
  overflow-y: hidden;
}
body,
#root {
  min-height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.headerLogo {
  /* background-image: url("/images/logo.png"); */
  background-repeat: no-repeat;
}

/** Header **/
.supportPhone {
  cursor: pointer;
  font-size: "10px";
  color: #000000;
  text-decoration: none;
}

.supportPhone:hover {
  text-decoration: underline;
}

/** Case Management **/
.viewerMenuBar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: "100%";
}

.annotationToolTray::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.annotationToolTray::-webkit-scrollbar-thumb:hover {
  background-color: #af1730;
}

.annotationToolTray::-webkit-scrollbar-track-piece:hover {
  background-color: #e4e4e4;
}

.annotationToolTray::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
}

/** Preloaded Documents */
.preloadedDocuments::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.preloadedDocuments::-webkit-scrollbar-thumb:hover {
  background-color: #af1730;
}

.preloadedDocuments::-webkit-scrollbar-track-piece:hover {
  background-color: #e4e4e4;
}

.preloadedDocuments::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  border-left: 1px solid #ddd;
}
.MuiPopover-paper .MuiListItemIcon-root {
  min-width: 40px;
}

fieldset {
  border: 0;
}

form div[class*="MuiDialogContent-root"] {
  overflow-x: hidden;
}

/* Styles to keep inconsistency with Huseby-Client-Meeting  */
div[class*="MuiDataGrid-columnSeparator"] {
  color: rgba(224, 224, 224, 1) !important;
}

/** Override background color **/
.MuiButton-containedSecondary {
  color: #ffffff !important;
}

/** Remove blue outline on buttons **/
.MuiButtonBase-root:focus,
.MuiButtonBase-root:focus-within {
  outline: none !important;
}

/** Remove blue outline on the DataGrid header and cells **/
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

/* Transcript Viewer  */
.mosaic-window-body > div {
  margin: 0 !important;
  overflow-y: hidden !important;
}

span[class*="search"] {
  align-self: center;
}

/* This is necessary for sticky headers in TranscriptViewer with react-virtualized. */
.ReactVirtualized__Table__headerRow {
  position: sticky;
  inset-block-start: 0;
  z-index: 1;
}
